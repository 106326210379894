import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import request from './request'
import { i18n } from './i18n'
import './utils/rem'

// import config from './config'

import Particles from 'particles.vue'
Vue.use(Particles)

import animated from "animate.css"
Vue.use(animated)

import MuseUI from "muse-ui"
import 'muse-ui/dist/muse-ui.css'
// import theme from 'muse-ui/lib/theme';
// theme.use('dark');
Vue.use(MuseUI)
import Toast from 'muse-ui-toast';

Vue.use(Toast,{
  position: 'top'
});


import "./assets/common.css"
// import "./museConfig.js"

// import Antd from 'ant-design-vue'
// import "ant-design-vue/dist/antd.less"
// Vue.use(Antd)

// import {Icon} from 'ant-design-vue'
// const IconFont = Icon.createFromIconfontCN({
//   scriptUrl: "//at.alicdn.com/t/font_2923432_s4pvijw2y8h.js",
// });
// Vue.component('IconFont', IconFont)



Vue.prototype.$http = request;
Vue.config.productionTip = false


new Vue({
  router, store, i18n,
  render: h => h(App),
}).$mount('#app')
