import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const Home = () => import ('../views/Home');
const HowPlay = () => import ('../views/Howplay');
const Game = () => import ('../views/Game');
const Records = () => import ('../views/Records');
const routes = [{
    path: '/',
    name: 'home',
    component: Home
}, {
    path: '/howtoplay',
    name: 'howplay',
    component: HowPlay,
}, {
    path: '/game',
    name: 'game',
    component: Game,
}, {
    path: '/records',
    name: 'records',
    component: Records,
}
];

const router = new VueRouter({
    mode: "hash",
    routes
})

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
      document.title = to.meta.title
    }
    next()
  })
export default router;