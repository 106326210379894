export default {
    records: {
        method: 'get',
        url: '/records',
        hasToken: false
    },
    winners: {
        method: 'get',
        url: '/wins',
        hasToken: false
    },
    games: {
        method: 'get',
        url: '/games',
        hasToken: false
    },
    roads: {
        method: 'get',
        url: 'roads',
        hasToken: false
    },lastBet: {
        method: 'get',
        url: 'lastBet',
        hasToken: false
    },
    // bindAddress: {
    //     method: 'post',
    //     url: 'bindAddress',
    //     hasToken: false
    // }
}