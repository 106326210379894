<template>
  <div class="footer">
    <div class="copyright">© SDD HASH JOY 2024</div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>

.footer{
  padding: 88px 0 38px 0;
  background-image: url('~@/assets/images/foot_bg.jpg');
  background-size: cover;
  color: #debb77;
}
</style>
