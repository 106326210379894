module.exports = {
    'common': {
        'confirm': '确认',
        'cancel':'取消',
        'submitSuccess':'數據已提交',
        'approve': '授權',
        'service': '服務',
        'lang': '語言',
        'increaseApprove': '增加',
        'decreaseApprove': '減少',
        'howToPlay': '玩法說明',
        'records': '記錄',
        'moneyMustBetween': '金額區間{minAmount}-{maxAmount}',
        'gameNames': ['幸運哈希','龍虎鬥','百家樂'],
        'gameRoadNames': ['幸',['龍','虎'],['閒','對子','莊']],
        'chooseBankerOrPlayer': '請選擇莊閒',
        'chooseOddOrEven': '請選擇龍虎',
        'chooseBetAmount': '選擇投注金額',
        'banker': '莊',
        'player': '閒',
        'tie': '對子',
        'odd': '虎',
        'even': '龍',
        'winBtn': '馬上贏取',
        'back': '返回',
        'loadMore': '加載更多',
        'noMoreData': '沒有更多數據了',
        'fresh': '刷新',
        'blockHash': '區塊HASH',
        'blockNumber': '區塊高度',
        'game': '遊戲',
        'direction': '押註',
        'amount': '金額',
        'winNow': '現在加入',
        'rWin': '贏啦',
        'rGot': '獲得',
        'rView': '查看',
        'approveModalTitle': '授權',
        'betAgainModalTitle': '倍/復投',
        'approveTips': '授權合約交易SDD方能參與遊戲',
        'safeTips': 'Hashsoul的智能合約已嚴格審計，絕無盜U風險，敬請玩家放心體驗',
        'approveLeft': '剩餘授權額度',
        'contractAddress': '合約地址',
        'iKnow': '我知道了',
    },
    'bet':{
        'clear': '清空',
        'betAmount': '投注金額',
        'mostEarn': '預估收益',
        'lastBet': '上次投注: ',
        'betAgain': '復投',
        'betDouble': '倍投',
    },
    'tips':{
        'goodLuck': '祝你好運!',
        'youWillGet': '如果您幸运，可以獲得最高 {reward} 的奖金',
        'noMoreApprove': '授權額度不足,請增加授權額度',
        'maxBetValue': '單次最大投注金額 {value}u',
        'pity':'很遺憾，這次您沒有中獎，請再接再厲',
        'globalWarings':[
            '務必保管好您的錢包秘鑰或助記詞','合約的唯一地址是',
            '不要截屏保存秘鑰或助記詞'
        ],
    },
    'play':[
        'SDD HASH JOY完全去中心化的游戏平台。该平台特点和优势是：',
        '完全去中心化，通过波场的智能合约进行运营，无人工干涉。智能合约自生成起就不可变动，目前所有游戏的输赢判断均以该笔下注交易的区块哈希判断，无法作弊无法修改，数据均在链上可查且不可篡改；',
        '完全匿名，任何人无法得知参与游戏的人是谁；',
        '智能合约自动判断输赢并自动支付奖金，玩家可以通过智能合约地址查询奖池金额和余额，也可以通过智能合约地址在链上查询交易，完全真实，无刷，也不存在杀率；',
        '目前游戏支持SDD，未来会开放包括TRX、USDT、ETH、BTC等多种币种，丰富游戏的可玩性及挑战；',        
    ],
    'playGame':[
        [
            '您可以在50-2000之间自由下注，当您下注以后会产生一笔向智能合约的SDD转账，转账金额为您的下注金额。该笔转账会在区块链上生成一笔交易的区块哈希，我们的记录里面也会展示该笔哈希值，您也可以通过自己的钱包查询该笔哈希值。如果您的该笔下注的交易哈希最后两位同时包括字母和数字（例如A1或9D等），您将获得您下注金额195%的奖励，例如您下注100SDD，会得到195SDD；',
            '注意：由于采用了基于波场的智能合约交易，每次交易均需要燃烧TRX作为手续费用，我们已为客户承担了50%甚至更高的交易成本，但是客户仍然需要支付部分手续费，尤其需要注意的是，如果您钱包余额中的trx过少可能会无法接收到我们的奖励，请您下注前务必确保自己有一定的trx余额。',
        ],
        [
            '您可以在50-2000之间自由选择龙或虎进行下注，其中龙代表偶数（双数），虎代表奇数（单数），当您下注以后会产生一笔向智能合约的SDD转账，转账金额为您的下注金额。该笔转账会在区块链上生成一笔交易的区块哈希，我们的记录里面也会展示该笔哈希值，您也可以通过自己的钱包查询该笔哈希值。',
            '如果您选择了龙，输赢规则为该笔交易哈希的最后从右向左数，第一个数字为（0,2,4,6,8）则龙赢，可获得195%的下注金额奖励，例如您下注100SDD，会得到195SDD；如果您下注是龙时，数字为0时，例如您下注100SDD，会得到195SDD。',
            '如果您选择了虎，输赢规则为该笔交易哈希的最后从右向左数，第一个数字为（1,3,5,7,9）则虎赢，可获得195%的下注金额奖励，例如您下注100SDD，会得到200SDD；如果您下注是虎时，数字为1时，例如您下注100SDD，会得到195SDD。',
            '注意：由于采用了基于波场的智能合约交易，每次交易均需要燃烧TRX作为手续费用，我们已为客户承担了50%甚至更高的交易成本，但是客户仍然需要支付部分手续费，尤其需要注意的是，如果您钱包余额中的trx过少可能会无法接收到我们的奖励，请您下注前务必确保自己有一定的trx余额。',
        ],
        [
            '您可以在50-2000之间自由选择庄、闲或者对子进行下注。当您下注以后会产生一笔向智能合约的SDD转账，转账金额为您的下注金额。该笔转账会在区块链上生成一笔交易的区块哈希，我们的记录里面也会展示该笔哈希值，您也可以通过自己的钱包查询该笔哈希值。',
            '如果您选择了庄，输赢规则为该笔交易哈希的最后两位同为数字或同为字母（例如AB或86，不包含对子）则庄赢，可获得195%的下注金额奖励，例如您下注100SDD，会得到195SDD。',
            '如果您选择了闲，输赢规则为该笔交易哈希最后两位同时包括数字和字母（例如A3、D8等）则闲赢，您可以获得195%的奖励，例如您下注100SDD，会得到195SDD。',
            '如果您选择了对子，输赢规则为该笔交易哈希最后两位为相同的字母或数字（例如AA、BB、JJ、66、88、99），则对子赢，该笔交易的奖励比例为1425%，例如您下注100SDD，会得到1425SDD。',
            '您的每笔下注会在记录及牌路中保留，便于您进行游戏决策和核对；',
            '注意：由于采用了基于波场的智能合约交易，每次交易均需要燃烧TRX作为手续费用，我们已为客户承担了50%甚至更高的交易成本，但是客户仍然需要支付部分手续费，尤其需要注意的是，如果您钱包余额中的trx过少可能会无法接收到我们的奖励，请您下注前务必确保自己有一定的trx余额。',
        ],
        // [
        //     '您可以在20-200之间自由下注，当您下注以后会产生一笔向智能合约的SDD转账，转账金额为您的下注金额。该笔转账会在区块链上生成一笔交易的区块哈希，我们的记录里面也会展示该笔哈希值，您也可以通过自己的钱包查询该笔哈希值。如果您该笔下注的区块哈希最后两位为相同的字母或数字（例如AA、BB、88、66等），您将获得该笔下注金额1600%的奖励，例如您下注100SDD，将获得1600SDD；',
        //     '注意：由于采用了基于波场的智能合约交易，每次交易均需要燃烧TRX作为手续费用，我们已为客户承担了50%甚至更高的交易成本，但是客户仍然需要支付部分手续费，尤其需要注意的是，如果您钱包余额中的trx过少可能会无法接收到我们的奖励，请您下注前务必确保自己有一定的trx余额。',
        // ]
    ],
}