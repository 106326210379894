<template>
    <div class="header">
      <div class="bg">
        <img src="@/assets/images/top_bg.jpg" alt="">
      </div>
      <div class="left" @click="$router.push({name:'howplay'})">
        <img src="@/assets/images/play_icon.png" alt="">
      </div>
      <!-- <div class="logo">
        <img src="@/assets/logo.png" alt="">
      </div> -->
      <div class="right">
        <img v-if="$store.state.closablePage==true" @click="closePage" src="@/assets/icons/close.svg" alt="">
        <img v-else @click="showDrawer" src="@/assets/images/list_icon.png" alt="">
      </div>
      <div class="dragon">
        <img src="@/assets/images/dragon.png" alt="">
      </div>
    </div>
</template>
<script>
export default {
  name: 'Header',
  data(){
    return {
      visible:false,
      docked:true,
      position:'right'
    }
  },
  components:{
  },
  methods:{
    afterVisibleChange(val) {
      console.log('visible', val);
    },
    showDrawer() {
      // this.visible = true;
      this.$store.commit("setMask", true);
      this.$emit('openDrawer');
    },
    closePage(){
      this.$store.commit("setClosablePage",false);
      this.$router.push({name:'home'});
    },
    onClose() {
      this.visible = false;
      this.$store.commit("setMask", false);

    },
    changeLang(val){
      this.$store.commit('setLang', val);
      this.$i18n.locale = val;
    },
  }
}
</script>
<style lang="less" scoped>
.header{
  
  position: relative;
  height: 680px;
  .left {
    z-index: 1;
    position: absolute;
    top: 30px;left: 30px;
    img{
      // display: block;
      width: 40px;
      // height: 28px;
    }
  }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    img {
      width: 100%;
    }
  }
  .dragon {
    position: absolute;
    bottom: 20px;
    width: 50%;
    left: 25%;
    img {
      width: 100%;
    }
  }
  

  .right{
    z-index: 1;
    position: absolute;
    top: 30px;right: 30px;
    img{
      width: 40px;
    }
  }
}
</style>
