module.exports = {
    'common': {
        'confirm': 'Confirm',
        'cancel':'Cancel',
        'submitSuccess':'success',
        'approve': 'Approve',
        'service': 'Service',
        'lang': 'Language',
        'increaseApprove': 'Increase',
        'decreaseApprove': 'Decrease',
        'howToPlay': 'How to play?',
        'records': 'Records',
        'moneyMustBetween': 'Amount must between {minAmount}-{maxAmount}',
        'gameNames': ['LuckyHash','TigerDragon','Baccarat'],
        'gameRoadNames': ['LH',['D','T'],['P','D','B','M']],
        'chooseBankerOrPlayer': 'Choose Banker or Player',
        'chooseOddOrEven': 'Choose Dragon or Tiger',
        'chooseBetAmount': 'Choose bet amount',
        'banker': 'BANKER',
        'player': 'PLAYER',
        'tie': 'TIE',
        'odd': 'TIGER',
        'even': 'DRAGON',
        'winBtn': 'WIN',
        'back': 'Back',
        'loadMore': 'Load More',
        'noMoreData': 'No More Data.',
        'fresh': 'Refresh',
        'blockHash': 'BlockHash',
        'blockNumber': 'BlockNumber',
        'game': 'Game',
        'direction': 'Direction',
        'amount': 'Amount',
        'winNow': 'WIN NOW!',
        'rWin': 'WIN',
        'rGot': 'Got',
        'rView': 'view on',
        'approveModalTitle': 'Approve',
        'betAgainModalTitle':'Quick bet',
        'approveTips': 'Approve HashSoul transaction SDD to join the game',
        'safeTips': 'Contract of Hashsoul has been calculated. Players are invited to rest assured that they are free of charge.',
        'approveLeft': 'Approved quota left',
        'contractAddress': 'Contract Address',
        'iKnow':'I ALREADY KNOW',
    },
    'bet':{
        'clear': 'X',
        'betAmount': 'Bet',
        'mostEarn': 'Most Earn',
        'lastBet': 'Last Bet: ',
        'betAgain': 'REPEAT',
        'betDouble': 'ADDITION',
    },
    'tips':{
        'goodLuck': 'Good Luck!',
        'youWillGet': 'You will get most {reward}, if you are lucky guy:)',
        'noMoreApprove': 'Insufficient approved quota ',
        'maxBetValue': 'Max amount is {value}',
        'pity':"It's a pity that you didn't win this time. Please pick it up again.",
        'globalWarings':[
            'Be sure to keep your wallet key or mnemonic safe','Contract address is TA8QWzE5gRK9xukMcZFiQxJhdyG7wRLdLv',
            'Do not take screenshots to save secret keys or mnemonics'
        ]
    },
    'play':[
        'SDD HASH JOY is a fully decentralized gaming platform. The platform features and advantages are: ',
        'Fully decentralized, operating through Trons smart contracts without human interference. The smart contract is immutable since it is generated. At present, the win or loss judgment of all games is based on the block hash judgment of the betting transaction, which cannot be cheated or modified, and the data is searchable and immutable on the chain. ',
        'Complete anonymity, with no way for anyone to know who is playing the game; ',
        'Smart contracts automatically judge wins and losses and automatically pay bonuses, customers and agents can query the amount and balance of the prize pool through the smart contract address, and can also query transactions on the chain through the smart contract address, completely real, brushless, and there is no kill rate; ',
        'Currently the game supports SDD, the future will be open including TRX, USDT, ETH, BTC and other currencies, rich game playability and challenge; ',
    ],
    'playGame':[
        [
            'You can bet freely between 50 and 2000, and when you bet, a SDD transfer is made to the smart contract for the amount of your bet. This transfer will generate a block hash of the transaction on the blockchain, which will also be displayed in our records, and you can also query the hash through your wallet. If the last two digits of your trade hash for that bet include both letters and numbers (e.g. A1 or 9D, etc.), you will receive 195% of the amount you bet. For example, if you bet 100SDD, you will receive 195SDD. ',
            'Attention: Due to the adoption of Tron based smart contract trading, each transaction needs to burn TRX as a processing fee, we have borne 50% or more of the transaction cost for our customers, but customers still need to pay part of the processing fee, in particular, it is important to note that if your wallet balance is too low trx may not receive our rewards. Please make sure you have a certain trx balance before placing your bet.',
        ],
        [
            'You can freely choose between 50-2000 dragon or tiger to bet, where the dragon represents an even number (even number) and the tiger represents an odd number (odd number), and when you bet, a SDD transfer will be generated to the smart contract, the transfer amount is the amount of your bet. This transfer will generate a block hash of the transaction on the blockchain, which will also be displayed in our records, and you can also query the hash through your wallet.',
            'If you choose the dragon, the win-loss rule is the last number of the trade hash from right to left, the first number is (0,2,4,6,8,), the dragon wins, you can get 195% of the bet amount reward, for example, you bet 100SDD, you will get 195SDD; If you bet on dragons and the number is 0, for example if you bet 100SDD, you will get 195SDD.',
            'If you choose Tiger, the win-loss rule is that the last number of the trade hash from right to left, the first number is (1,3,5,7,9), the tiger wins, you can get 195% of the bet amount reward, for example, you bet 100SDD, you will get 195SDD; If you bet tiger and the number is 1, for example, you bet 100SDD and get 195SDD.',
            'Attention: Due to the adoption of Tron based smart contract trading, each transaction needs to burn TRX as a processing fee, we have borne 50% or more of the transaction cost for our customers, but customers still need to pay part of the processing fee, in particular, it is important to note that if your wallet balance is too low trx may not receive our rewards. Please make sure you have a certain trx balance before placing your bet.',
        ],
        [
            'You can freely choose between 50 and 2000 to bet, leisure or bet. When you place a bet, an SDD transfer is made to the smart contract, and the amount transferred is the amount of your bet. This transfer will generate a block hash of the transaction on the blockchain, which will also be displayed in our records, and you can also query the hash through your wallet.',
            'If you choose Banker, the win-lose rule is that if the last two digits of the trade hash are both numbers or letters (such as AB or 86, excluding pairs), Banker wins and you get 195% of the bet amount, for example, if you bet 100SDD, you get 195SDD.',
            'If you choose player, the win-lose rule is that if the last two digits of the trade hash include both numbers and letters (e.g. A3, D8, etc.), player wins, you can get 195% reward, for example, if you bet 100SDD, you will get 195SDD.',
            'If you choose a pair, the win-lose rule is that the last two digits of the trade hash are the same letters or numbers (for example AA, BB, JJ, 66, 88, 99), then the pair wins, and the reward ratio of the trade is 1425%, for example, you bet 100SDD, you get 1425SDD.',
            'Each of your notes will be kept in the record and card path, so that you can make game decisions and check;',
            'Attention: Due to the adoption of Tron based smart contract trading, each transaction needs to burn TRX as a processing fee, we have borne 50% or more of the transaction cost for our customers, but customers still need to pay part of the processing fee, in particular, it is important to note that if your wallet balance is too low trx may not receive our rewards. Please make sure you have a certain trx balance before placing your bet.',
        ],
        // [
        //     '您可以在20-200之间自由下注，当您下注以后会产生一笔向智能合约的SDD转账，转账金额为您的下注金额。该笔转账会在区块链上生成一笔交易的区块哈希，我们的记录里面也会展示该笔哈希值，您也可以通过自己的钱包查询该笔哈希值。如果您该笔下注的区块哈希最后两位为相同的字母或数字（例如AA、BB、88、66等），您将获得该笔下注金额1600%的奖励，例如您下注100SDD，将获得1600SDD；',
        //     '注意：由于采用了基于波场的智能合约交易，每次交易均需要燃烧TRX作为手续费用，我们已为客户承担了50%甚至更高的交易成本，但是客户仍然需要支付部分手续费，尤其需要注意的是，如果您钱包余额中的trx过少可能会无法接收到我们的奖励，请您下注前务必确保自己有一定的trx余额。',
        // ]
    ],
}