import Vue from 'vue';
import Vuex from 'vuex';
import { getSession, setSession, getLocalStorage, setLocalStorage } from '../utils/funs/storage';
import config from '../config';
Vue.use(Vuex);

export default new Vuex.Store({
	state: {
        walletAddress: getLocalStorage('wallet_address') || '',
		gameRewards:getLocalStorage('game_rewards') || config._DEFAULT_GAME_REWARDS_,
        lang: getLocalStorage(config._LANG_KEY) || config._DEFAULT_LANG,
		token: getSession(config._TOKEN_KEY),
		showMask: false,
		roads:[],
		closablePage:false,
		currentGameId: getLocalStorage('current_game_id') || 0,
		approvedAmount: getLocalStorage('approved_amount') || 0,
		showApproveModal: false,
	},
	mutations: {
		setGameRewards(state, gameRewards){
			state.gameRewards = gameRewards || config._DEFAULT_GAME_REWARDS_;
			setLocalStorage('game_rewards', gameRewards);
		},
		setWalletAddress(state, address) {
			state.walletAddress = address;
			setLocalStorage('wallet_address', address);
		},
		setClosablePage(state, closable) {
			state.closablePage = closable;
		},
		setCurrentGameId(state, gid){
			state.currentGameId = gid;
			setLocalStorage('current_game_id', gid);
		},
		setApprovedAmount(state, amount){
			state.approvedAmount = amount;
			setLocalStorage('approved_amount', amount);
		},
		setShowApproveModal(state, show){
			state.showApproveModal = show;
			// setSession('show_approve_modal', show);
		},
		setLang(state, lang) {
			state.lang = lang || config._DEFAULT_LANG;
			setLocalStorage(config._LANG_KEY, lang);
		},
		setToken(state, token) {
			state.token = token;
			setSession(config._TOKEN_KEY, token);
		},
		setMask(state, showMask){
			state.showMask = showMask
		},
		setRoads(state, data){
			state.roads = data;
			// state.roads[data.game_id] = data.roads;
		},
		pushRoad(state, data) {
			// state.roads[data.game_id].push(data);
			state.roads.push(data);
		}
	},
	actions: {

	},
	modules: {

	}
});
