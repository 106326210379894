<template>
  <div id="app">
    <div :class="isBlur">
      <Header @closePage="pageClosed" @openDrawer="drawerOpen=true" />
      <div class="main">
        <router-view />
      </div>
      <Footer class="zdx-1" />
    </div>
    
    <div v-if="$store.state.showMask" class="loading-mask">
      <div @click="toggleMask" class="mask"></div>
    </div>

    <div class="sidebar">
      <mu-drawer :open.sync="drawerOpen" :right="true">
        <img class="rotate90" @click="drawerClose" src="@/assets/icons/list.svg" alt="">
        <mu-list>
          <!-- <mu-list-item button @click="approveModal">
            <img src="@/assets/icons/Approve.svg" alt="">
            <span class="text">{{$t('common.approve')}}</span>
          </mu-list-item>
          <mu-list-item button @click="serviceModal">
            <img src="@/assets/icons/Service.svg" alt="">
            <span class="text">{{$t('common.service')}}</span>
          </mu-list-item> -->
          <mu-list-item button @click="showLanguage = !showLanguage">
            <img src="@/assets/icons/language.svg" alt="">
            <span class="text">{{$t('common.lang')}}</span>
          </mu-list-item>
          <div v-if="showLanguage">
            <mu-list-item class="son-item" :key="'lang' + i" v-for="l,i in langs">
              <mu-radio @change="changeLang(l.value)" :value="l.value" v-model="lang"  :label="l.text"></mu-radio>
            </mu-list-item>
          </div>
          
        </mu-list>
      </mu-drawer>
    </div>
    

    
    <!-- <div v-if="showServiceModal" class="serviceModal">
      <img src="@/assets/icons/hint.svg" alt="">
      <div class="notice">
        {{$t('tips.globalWarings')[0]}}
      </div>
      <div class="black">
        <div class="title">{{$t('common.contractAddress')}}</div>
        <span class="text">{{contractAddress}}</span>
      </div>
      <div class="btn primary h40" @click="toggleMask">{{$t('common.iKnow')}}</div>
    </div> -->
    <!-- <div class="global-warning animate__animated animate__fadeInDown">
      <a-alert :message="globalWarning" banner closable type="warning"/>
    </div> -->
    <Particles v-if="fire" id="fireworkparticles"  :options="fireworkConfig" />

  </div>
</template>

<script>
import FireworkConfig from "./assets/particlesConfig/firework.json";
import Header from "./components/Header.vue";
// import QuickBet from './components/QuickBet.vue';
import Footer from './components/Footer.vue';
// import config from './config';

import Echo from "laravel-echo"
window.Pusher = require('pusher-js');
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'cb20574d91ab85bc10bb',
    cluster: 'ap2',
    encrypted: true    //与broadcasting.php保持一致
});

export default {
  name: 'App',
  components: {
    Header,
    // Howplay,
    // QuickBet,
    // Game,
    Footer
  },
  data(){
    return {
      drawerOpen:false,
      fire: false,
      gameRewards: [[195,0,0],[195,195,0],[195,1425,195]],
      showMask: true,
      fireworkConfig: FireworkConfig,
      lastBetData: {},
      // showApproveModal: this.$store.state.showApproveModal,
      showServiceModal: false,
      gamePanelVisible: false,
      langs: [{'value':'en','text':'English'},{'value':'hk','text':'繁体'}],
      lang: this.$store.state.lang,
      // approvedAmount: 0,
      approveAmounts: 2000,
      showLanguage:false,
      // contractAddress:config._HS_CONTRACT_ADDR,
    }
  },
  computed:{
    isBlur(){
      return this.$store.state.showMask?'blur':'';
    },
  },
  created(){
    this.$i18n.locale = this.$store.state.lang;
    let _this = this;    
    let _obj = setInterval(()=>{
      if (window.walletAddress && window.HASH_CONTRACT && window.USDT_TOKEN && window.Echo) {
          clearInterval(_obj)
          //debug 
          // window.walletAddress = "TYALdfFALWQsAUuXHvi9kmkU7ZYNEdFprc";
          //debugEND
          window.Echo.channel('iwina.'+window.walletAddress).listen("SomeoneWin", (e) => {
              console.log(e.winner);
              _this.$store.commit('pushRoad', e.winner);              
              if(e.winner.win){
                _this.someoneWin(e.winner);
              }else{
                _this.$toast.info(_this.$t('tips.pity'));
              }
          });
          
          // _this.getLastBet();
          
          // _this.getAllowance();
          
      }
    }, 10);
    
    // setInterval(()=>{
    //   let rnd = Math.floor(Math.random()*_this.$t('tips.globalWarings').length);
    //   _this.globalWarning = _this.$t('tips.globalWarings')[rnd];
    // },5000);
  },
  methods: {
    drawerClose(){
      this.$store.commit("setMask", false);
      this.drawerOpen = false;
    },
    approveModal(){
      this.drawerOpen = false;
      this.showServiceModal = false;
      this.$store.commit("setMask", true);
      this.$store.commit("setShowApproveModal", true);
      // this.showApproveModal = true;
    },
    serviceModal(){
      this.drawerOpen = false;
      // this.showApproveModal = false;
      this.$store.commit("setShowApproveModal", false);

      this.$store.commit("setMask", true);
      this.showServiceModal = true;
    },
    pageClosed(){
      this.howplayExpand = false;
      this.gamePanelVisible = false;
      this.$store.commit("setGameListVisible", true);

    },
    
    toggleMask(){
      let showMask = this.$store.state.showMask;
      this.$store.commit("setMask", !showMask);
      this.drawerOpen = false;
      // this.showApproveModal = false;
      this.$store.commit("setShowApproveModal", false);
      this.showServiceModal = false;

    },
  someoneWin(winner) {
      this.fire = true;
      setTimeout(()=>{
        this.fire = false;
      }, 5000)
      this.$toast.success({
        message:'Congratulations! You just got '+winner.reward/1000000+'usdt',
        time: 5000
      });
      
    },
    getLastBet(){
      let _this = this;
      let data = {address: window.walletAddress};
      this.$http.call(this.$http.api.lastBet, {
        params: data
      }).then(function(res){
        _this.lastBetData = res.data;
      },function(){
        console.log('something goes wrong...');
      });
    },
    quickBet(type){
      console.log(type)
    },
    changeLang(lang){
      this.$store.commit("setLang", lang);
      this.$i18n.locale = lang;
    },
    // async getAllowance(){
    //   let _this = this;
    //   window.USDT_TOKEN.allowance(window.walletAddress, config._HS_CONTRACT_ADDR).call().then((res)=>{
    //     let _approveAmount=0;
    //       if(res.remaining===undefined){
    //         _approveAmount = res.toNumber();
    //       }else{
    //         _approveAmount = res.remaining.toNumber();
    //       }
    //     _this.$store.commit("setApprovedAmount", _approveAmount);
    //   });
    // },
    // approve(val,opt){
    //   if(opt != 0 && opt!=1){
    //     console.log('param error');
    //     return;
    //   }
    //   let _this = this;
    //   if(opt==1){
    //     window.USDT_TOKEN.increaseApproval(config._HS_CONTRACT_ADDR, val*1000000).send().then((res)=>{
    //     // window.USDT_TOKEN.increaseAllowance(config._HS_CONTRACT_ADDR, val*1000000).send().then((res)=>{
    //       console.log(res)
    //       _this.$toast.success(this.$t('common.submitSuccess'));
    //       setTimeout(()=>{
    //         _this.getAllowance();
    //       },1500)
    //     });
    //   }else{
    //     window.USDT_TOKEN.decreaseApproval(config._HS_CONTRACT_ADDR, val*1000000).send().then((res)=>{
    //     // window.USDT_TOKEN.decreaseAllowance(config._HS_CONTRACT_ADDR, val*1000000).send().then((res)=>{
    //       // console.log('approve after:')
    //       console.log(res)
    //       _this.$toast.success(this.$t('common.submitSuccess'));
    //       setTimeout(()=>{
    //         _this.getAllowance();
    //       },1500)
    //       // _this.approveModalVisable = false;
    //     });
    //   }
      
    // },
  }
}
</script>

<style lang="less">
#app {
  color: #ffffff;
  text-align: center;
  overflow: hidden;
  font-size: 28px;
  background: url("~@/assets/images/body_bg.jpg") repeat-y;
  background-size: 100%;
  border:0;
  min-height: 100vh;
}
.main{
  z-index: 9;
  min-height: calc(100vh - 800px);
  // background: @bg-color;
}
// .mu-drawer{
//   background: @bg-color;
// }
.sidebar .mu-drawer{
  padding: 2rem;
  background: linear-gradient(to bottom, #ecb97e, #d15834);
  color: #fae7d1;
  text-align: right;
  width: 350px;
  font-size: 28px;
  .mu-item{
    color:#fae7d1;
    background: #e4533b;
    height: 75px;
    line-height: 75px;
    margin: 5px 0;
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }
  .son-item{
    .mu-item{
      margin:0;
      justify-content: flex-end;
      
    }
  }
  .text{
    padding-left: 20px;
  }
  .mu-radio-label{
    color: #fff;
    font-size:28px;
  }
  .mu-radio{
    color: #fff;
  }
}
.rotate90{
  transform: rotate(90deg);
}
.approveModal{
  background: #fff;
  min-height:200px;
  position:fixed;
  top: 100px;
  left:5%;
  width: 90%;
  color: @bg-color;
  border-radius: 16px;
  padding-bottom: @base-pd;
  // word-break: break-all;
  word-wrap: break-word;
  .title{
    height:50px;
    line-height: 50px;
    border-bottom: 0.5px solid rgba(0,0,0,.1);
  }
  .approve-tips{
    color: #131317;
    // font-weight: 500px;
    // font-size: 10px;
    padding: @base-pd;
    line-height: 16px;
    font-style: normal;
    text-decoration: none;
  }
  .approved-amount{
    color: #1BA27A;/* 0.00 */
    font-weight: 700px;
    font-size: 32px;
    line-height: 32px;
  }
  .approve-left{
    padding: @base-pd;
  }
  .approve-ipt{
    padding: 20px;
    border: 0.5px solid rgba(0,0,0,.1);
    text-align: center;
    display:flex;
    align-items: center;
    margin: @base-pd;
  }
  #approve-amount{
    border:0;
    outline: none;
    flex:1;
    padding-left: @base-pd;
  }
  
  .btns{
    display: flex;
    justify-content: space-between;
    .btn{
      margin: @base-pd;
    }
  }
}
.serviceModal{
  background: #fff;
  position:fixed;
  top: 100px;
  left:5%;
  width: 90%;
  border-radius: 16px;
  word-wrap: break-word;
  padding: 2*@base-pd @base-pd;
  .notice{
    color:#FF0211;
    width: 70%;
    margin: 0 auto;
    padding: 7px;
  }
  .black{
    background: @bg-color;
    color:#fff;
    border-radius: 6px;
    margin: @base-pd 0;
    display:flex;
    flex-direction: column;
    .title{
      background: @btn-color;
      height:40px;
      line-height: 40px;
      border-radius: 6px;
    }
    .text{
      color: @primary-color;
      font-size:14px;
      padding: 2*@base-pd 0;
    }
  }
}
// .global-warning{
//   position:fixed;
//   width: 100%;
//   top: 0;
// }

</style>
